import { Button, Card, CardHeader, CardBody, CardFooter, Progress } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { listProjects } from "../api/gh";

export const TAG_COLORS = ["warning"]
export const PROGRESS_COLORS = ["$cranberry", "$ceramic", "$camel"]

export interface ProjectProps {
	title: string,
	desc: string,
	img: string,
	tags: Array<string>,
	progress?: number,
	link?: string,
	repo?: string,
	readme?: string,
	id?: number
}

export function ProjectCard({ p, fn, f }: { p: ProjectProps, fn?: Function, f?: Array<string> }) {
	const navigate = useNavigate();
	return (
		<Card
			style={{
				maxWidth: "1100px",
				width: "95%",
				margin: "0 auto",
			}}
			key={p.title}
			isHoverable
			isPressable
			onPress={() => {
				if (p.id || p.readme) {
					navigate(p.id
						? `/blog/${p.id}`
						: `/markdown?link=${p.readme}${p.link ? `&gh=${p.link}` : ""}`
					);
				} else {
					window.open(p.repo ? p.repo : p.link);
				}
			}}>
			<CardHeader>
				{p.title}
			</CardHeader>
			<CardBody>
				{p.desc}
			</CardBody>
			<CardFooter style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
				{p.tags.map((tag, i) => (
					<Button
						variant="shadow"
						color={f ? f.indexOf(tag) !== -1 ? "primary" : "success" : "success"}
						onClick={() => fn ? fn(tag) : null}
					>
						{tag}
					</Button>
				))}
			</CardFooter>
			{
				p.progress &&
				<Progress
					style={{ height: "5px", marginTop: "10px" }}
					color={Number(p.progress) < 100 ? "primary" : "success"}
					value={Number(p.progress)}
					isStriped={true}
				/>

			}
		</Card >
	)
}

export default function Projects() {
	const [projects, setProjects] = useState<Array<ProjectProps>>([]);
	const [filter, setFilter] = useState<Array<string>>([]);
	function handleClick(f: string) {
		if (filter.indexOf(f) !== -1) {
			setFilter(filter.slice(0, filter.indexOf(f)).concat(filter.slice(filter.indexOf(f) + 1)))
		} else {
			setFilter(filter.concat(f))
		}
	}
	useEffect(() => {
		// Safety check that projects are defined.
		listProjects().then((projects) => (projects !== undefined && setProjects(projects)))
	}, [])
	return (
		<div style={{
			display: "flex",
			flexDirection: "column",
			minHeight: "110vh",
			padding: "3rem 0",
			gap: "2rem"
		}}>
			{(projects !== undefined && projects
				.filter((p) => filter.length === 0 ? true : filter.every((f) => p.tags.indexOf(f) !== -1))
				.map((project) => (<ProjectCard p={project} fn={handleClick} f={filter} />))
			)
			}
		</div>
	)
}
