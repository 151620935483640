import { Navbar, NavbarContent, NavbarItem, Link, NextUIProvider } from '@nextui-org/react';
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { ThemeProvider as NextThemeProvider } from 'next-themes';
import Footer from '../components/Footer';
import { ScrollToTop } from '../components/ScrollToTop';

const PAGES: Array<{ name: string; path: string }> = [
	{
		name: "Home",
		path: "/",
	},
	{
		name: "Projects",
		path: "/projects",
	},
	{
		name: "Blog",
		path: "/blog",
	},
	{
		name: "Reading",
		path: "/reading",
	},
]

export default function Root() {
	const pathname = "/" + useLocation().pathname.split("/")[1];

	return (
		<NextUIProvider>
			<ScrollToTop />
			<NextThemeProvider>
				<Navbar isBordered shouldHideOnScroll>
					<NavbarContent style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}>
						{PAGES.map(({ name, path }) => (
							<NavbarItem
								style={{ margin: "auto .12rem 1.5rem" }}
								isActive={
									pathname === path
									|| (pathname === "/markdown" && path === "/projects")
								}>
								<Link
									style={{
										fontSize: "var(--nextui-font-size-large)",
										margin: "auto"
									}}
									color={
										(pathname === path
											|| (pathname === "/markdown" && path === "/projects"))
											? "success"
											: "foreground"
									}
									to={path}
									as={NavLink}>
									{name}
								</Link>
							</NavbarItem>
						))}
					</NavbarContent>
				</Navbar>
				<Outlet />
				<Footer />
			</NextThemeProvider >
		</NextUIProvider >
	);
}
