import { Button } from "@nextui-org/react";
import { TypeAnimation } from "react-type-animation";

export default function Home() {
	return (
		<div
			style={{
				minHeight: "100vh",
				gap: "2rem",
				display: "flex",
				flexDirection: "column",
				alignItems: "center"
			}}
		>
			<div
				style={{
					marginTop: "30vh",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					gap: "2rem"
				}}
			>
				<div
					style={{
						fontSize: "3rem",
						margin: "auto",
						marginTop: "0rem",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<h1
						style={{
							margin: "auto",
							display: "flex",
							flexDirection: "row"
						}}
					>
						Hey, I'm <p style={{ fontWeight: "bolder", marginLeft: ".75rem" }}> Emil</p>
					</h1>
					<TypeAnimation sequence={[
						"Artificer",
						1500,
						"Dreamer",
						1500,
						"Sojourner",
						1500,
					]}
						speed={10}
						deletionSpeed={30}
						style={{ margin: "auto" }}
						repeat={Infinity}
					/>
				</div>
				<div
				>
					<Button
						variant="shadow"
						color="success"
						className="mx-[auto]"
						onPress={() => download(
							"https://blog-sync-01.s3.amazonaws.com/public/rsrcs/emil_hofstetter.pdf",
							"pdf",
							"emil_hofstetter_resume"
						)}
					>
						Resume
					</Button>
				</div>
			</div>
		</div >
	)
}

function download(url: string, ft: string = "pdf", fn?: string) {
	fetch(url, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/pdf',
		},
	})
		.then((response) => response.blob())
		.then((blob) => {
			// Create blob link to download
			const url = window.URL.createObjectURL(
				new Blob([blob]),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`${fn ? fn : "file" + Date.now().toString()}.${ft}`,
			);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// Clean up and remove the link
			// @ts-ignore
			link.parentNode.removeChild(link);
		});
}
