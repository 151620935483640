import { useEffect, useState } from "react";
import { listReadings } from "../api/gh";
import { ProjectCard } from "./Projects";

export interface Reading {
	title: string;
	desc: string;
	img: string;
	progress: number;
	link: string;
	authors: Array<string>;
	tags: Array<string>;
}

export default function Readings() {
	const [filter, setFilter] = useState<Array<string>>([]);
	function handleClick(f: string) {
		if (filter.indexOf(f) !== -1) {
			setFilter(filter.slice(0, filter.indexOf(f)).concat(filter.slice(filter.indexOf(f) + 1)))
		} else {
			setFilter(filter.concat(f))
		}
	}
	const [readings, setReadings] = useState<Array<Reading>>([]);
	useEffect(() => {
		listReadings().then(p => p.json()).then((p) => setReadings(p.readings))
	}, [])
	return (
		<div style={{
			display: "flex",
			flexDirection: "column",
			minHeight: "110vh",
			padding: "3rem 0",
			gap: "2rem"
		}}>
			{(readings !== undefined && readings
				.filter((p) => filter.length === 0 ? true : filter.every((f) => p.tags.indexOf(f) !== -1))
				.filter(r => r.progress < 100).map(({ title, desc, img, progress, link, tags }) => (
					<ProjectCard p={{ title, desc, img, progress, tags, link }} fn={handleClick} f={filter} />

				)))}
			{(readings !== undefined && readings
				.filter((p) => filter.length === 0 ? true : filter.every((f) => p.tags.indexOf(f) !== -1))
				.filter(r => r.progress >= 100).map(({ title, desc, img, progress, link, tags }) => (
					<ProjectCard p={{ title, desc, img, progress, tags, link }} fn={handleClick} f={filter} />

				)))}
		</div>
	)
}
