import {
  createBrowserRouter,
} from "react-router-dom";
import Blog from "./pages/Blog";
import BlogView from "./pages/BlogView";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Readings from "./pages/Readings";
import Root from "./pages/Root";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		children: [
			{
				path: "",
				element: <Home />
			},
			{
				path: "projects",
				element: <Projects />
			},
			{
				path: "blog",
				element: <Blog />
			},
			{
				path: "blog/:id",
				element: <BlogView />
			},
			{
				path: "markdown",
				element: <BlogView />
			},
			{
				path: "reading",
				element: <Readings />
			},
			{
				path: "contact",
				element: <Home />
			}
		]
	}
])

export default router;
