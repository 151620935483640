import { Button } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getPost as getGHPost } from "../api/gh";
import { FiGithub } from 'react-icons/fi'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { srcery as synStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import remarkUnwrapImages from 'remark-unwrap-images'
import "./BlogView.css"

export default function BlogView() {
	const { id } = useParams();
	const [searchParams] = useSearchParams()
	const readme = searchParams.get("link");
	const link = searchParams.get("gh");

	const [post, setPost] = useState("");

	useEffect(() => {
		id != null
			? getGHPost(id).then(res => res.text()).then(text => setPost(text))
			: fetch(readme).then(res => res.text()).then(text => setPost(text));
	}, [post, id, readme]);

	return (
		<div style={{
			margin: "auto",
			minHeight: "90vh",
			padding: "2rem 1rem",
			display: "flex",
			maxWidth: "900px",
			gap: "3rem",
			fontSize: "1rem",
			flexDirection: "column",
			fontFamily: "Source Serif Pro, serif",
		}}>
			<ReactMarkdown
				children={post}
				className="markdown"
				remarkPlugins={[remarkGfm, remarkUnwrapImages]}
				rehypePlugins={[rehypeRaw]}
				components={{
					code({ node, inline, className, children, ...props }) {
						const match = /language-(\w+)/.exec(className || '');
						return !inline && match ? (
							<div style={{
								backgroundColor: "transparent",
								borderRadius: "8px",
								overflowY: "scroll",
								border: "solid .5px",
								borderColor: "#ffffff40",
							}}
							>
								<div style={{
									backgroundColor: "#ffffff40",
									borderRadius: "8px 0 8px 0",
									fontSize: ".8rem",
									padding: ".13rem .33rem",
									width: "fit-content"
								}}
								>{String(match[1]).toUpperCase()}</div><SyntaxHighlighter
									children={String(children).replace(/\n$/, '')}
									style={synStyle}
									customStyle={{
										backgroundColor: "#1a1e27ff",
										color: "#ffffff",
										width: "100%",
										fontSize: "1rem",
										padding: ".8rem",
									}}
									language={match[1]}
									PreTag="div"
									{...props}
								/>
							</div>
						) : (
							<code className={className} {...props}>
								{children}
							</code>
						)
					}
				}}
			/>
			{
				link
					?
					<Button
						ghost
						style={{
							margin: "auto",
							width: "auto"
						}}
						auto
						icon={<FiGithub />}
						iconLeftCss={{ fs: "$lg" }}
						onPress={() => window.open(link, "_blank")}
					>
						Github
					</Button>
					:
					null
			}
		</div>
	)
}
