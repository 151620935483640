import { ProjectProps } from "../pages/Projects";
const DEV_URL = "http://localhost:9000/lambda-url"
const PROD_URL = "https://rdqeyd619c.execute-api.us-east-1.amazonaws.com/PROD"
const ENV_URL_PREF = () => process.env.NODE_ENV === "development" ? DEV_URL : PROD_URL;
const ENV_URL_SUF = () => process.env.NODE_ENV === "development" ? "" : "";
export function header(): HeadersInit {
	return {}
}

export async function getGHContent(path: string) {
	return fetch(ENV_URL_PREF() + "/gh_content", {
		method: "POST",
		body: JSON.stringify({
			path: path
		}),
	})
}

export async function getPost(id: string | number) {
	return fetch(ENV_URL_PREF() + `/get_post/${id}` + ENV_URL_SUF(), {
		method: "GET",
		headers: header()
	})
}

export async function getPostDetails(id: string | number) {
	return fetch(ENV_URL_PREF() + `/get_posts_details/${id}` + ENV_URL_SUF(), {
		method: "GET",
		headers: header()
	})
}

export async function listPosts() {
	return fetch(ENV_URL_PREF() + "/lists/posts" + ENV_URL_SUF(), {
		method: "GET",
		headers: header()
	})
}

export async function listReadings() {
	return fetch(ENV_URL_PREF() + "/lists/readings" + ENV_URL_SUF(), {
		method: "GET",
		headers: header()
	})
}

export async function listProjects(): Promise<ProjectProps[]> {
	return fetch(ENV_URL_PREF() + "/lists/projects" + ENV_URL_SUF(), {
		method: "GET",
		headers: header()
	}).then((res) => res.json()).then((projects) => projects.projects)
}
