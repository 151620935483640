import { Link } from "@nextui-org/react"

export default function Footer() {
	return (
		<div style={{
			display: "flex",
			borderTop: "solid .5px grey",
			padding: "2rem"
		}}
		>
			<div style={{
				width: "100%",
				display: "flex",
				flexDirection: "column",
			}}>
				<div style={{
					display: "flex",
					flexDirection: "column",
					marginBottom: "2rem"
				}}>
					<p >Contact</p>
					<Link href="mailto:emil.hof.dev@gmail.com">Email</Link>
				</div>
				<div style={{
					display: "flex",
					flexDirection: "column",
					marginBottom: "2rem"
				}}>
					<p >Socials</p>
					<Link href="https://www.github.com/emilHof">Github</Link>
					<Link href="https://www.linkedin.com/in/emil-hofstetter">Linkedin</Link>
				</div>
				<div style={{
					display: "flex",
					flexDirection: "column",
					marginBottom: "2rem"
				}}>
					<p >Attributions</p>
					<Link href="https://www.flaticon.com/free-icons/universe">
						Universe icons created by Freepik - Flaticon
					</Link>
				</div>
				<div >
					<p >
						Copyright © Emil Hofstetter 2022 - 2023
					</p>
				</div>
			</div>
		</div>

	)

}
