import { useEffect, useState } from "react";
import { listPosts } from "../api/gh";
import { ProjectCard } from "./Projects";

export interface BlogPosts {
	date: string;
	desc: string;
	id: number;
	listed: boolean;
	path: string;
	tags: Array<string>;
	title: string;
}

export default function Blog() {
	const [filter, setFilter] = useState<Array<string>>([]);
	function handleClick(f: string) {
		if (filter.indexOf(f) !== -1) {
			setFilter(filter.slice(0, filter.indexOf(f)).concat(filter.slice(filter.indexOf(f) + 1)))
		} else {
			setFilter(filter.concat(f))
		}
	}
	const [posts, setPosts] = useState<Array<BlogPosts>>([]);
	useEffect(() => {
		listPosts().then(p => p.json()).then((p) => setPosts(p.posts))
	}, [])
	return (
		<div style={{
			minHeight: "90vh",
			display: "flex",
			gap: "2rem",
			flexDirection: "column",
			padding: "3rem 0",
		}}>
			{(posts !== undefined && posts
				.filter((p) => filter.length === 0 ? true : filter.every((f) => p.tags.indexOf(f) !== -1))
				.map((post) => ({ ...post, img: "" })).map((card) => (
					<ProjectCard p={card} fn={handleClick} f={filter} />
				)))}
		</div>
	)
}
